import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import ResultModal from '../Shared/ResultModal/ResultModal';
import { StyledModal } from '../Shared/ResultModal/style';
import * as APIClient from '../../Utils/APIClient'

function IncidentAction(props) {
  const [resolveModalData, setResolveModalData] = React.useState({ open: false, reason: "", reasonEmpty: false });
  const [resultModalData, setResultModalData] = React.useState({ open: false, text: "", success: false });

  const handleResolveModalOpen = () => setResolveModalData({ ...resolveModalData, open: true });
  const handleResolveModalClose = () => {
    setResolveModalData({ open: false, reason: "", reasonEmpty: false }); props?.onResult(false)
  }

  const updateReason = (event) => {
    setResolveModalData({ ...resolveModalData, reason: event.target.value })
  }


  const onResolveAction = () => {
    if (resolveModalData.reason.trim() === '') {
      setResolveModalData({ ...resolveModalData, reasonEmpty: true })
    }
    else {
      handleResolveModalClose();
      setResultModalData({ open: true, loading: true, text: "Processing" })
      if (props.incidentId !== -1) {
        APIClient.ResolveIncident(props.incidentId, resolveModalData.reason).then((res) => {
          if (res?.eCode === 0) {
            setResultModalData({ open: true, loading: false, text: "Resolved successfully", success: true })
          }
          else {
            setResultModalData({ open: true, loading: false, text: "There was an error resolving incident", success: false })
          }
        })
          .catch((error) => {
            setResultModalData({ open: true, loading: false, text: "Request failed", success: false })
          })
      }
      else {
        setResultModalData({ open: true, loading: false, text: "Invalid parameters", success: false })
      }
    }
  }

  const onResultModalClose = () => { setResultModalData({ open: false }); props?.onResult(resultModalData.success)}

  React.useEffect(() => {
    if (props?.startResolveFlow && props?.incidentId !== -1) {
      handleResolveModalOpen();
    }
    else {
      handleResolveModalClose();
    }
  }, [props?.startResolveFlow])

  return (
    <Box>
      <Modal
        open={resolveModalData.open}
        onClose={handleResolveModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CheckCircleOutlineIcon className='main-icon success' />
            </Grid>
            <Grid item xs={12}>
              <h2>Are you sure that you want to resolve this incident?</h2>
            </Grid>
            <Grid item xs={12}>
                <label className="block text-start font-medium">RCA :</label>
                <TextField
                  id="outlined-multiline-static"
                  hiddenLabel
                  fullWidth
                  multiline
                  rows={4}
                  value={resolveModalData.reason}
                  onChange={updateReason}
                  variant="filled"
                />
                {resolveModalData.reasonEmpty ?
                  <h4 className='fail'>
                    Please enter RCA
                  </h4>
                  : ''}
              </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                className="btn-accept"
                onClick={onResolveAction}
              >
                Yes, Resolve
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                className="btn-cancel"
                onClick={handleResolveModalClose}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </StyledModal>
      </Modal>
      <ResultModal modalData={resultModalData} onClose={onResultModalClose} />
    </Box>
  );
}

export default IncidentAction;
