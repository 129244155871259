import * as React from 'react';
import { Link, generatePath } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Components/Shared/DataTable/DataTable';
import IncidentAction from './Incident/IncidentAction';
import DeleteModal from './Shared/ResultModal/DeleteModal'
import * as APIClient from '../Utils/APIClient'
import PATH_LIST from '../Utils/pathsConfig';


function Home(props) {
  const [pageSize, setPageSize] = React.useState(-1);
  const [pageNum, setPageNum] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(999999999);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [incidentId, setIncidentId] = React.useState(-1);
  const [startResolveFlow, setStartResolveFlow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(-1);
  const [startDeleteFlow, setStarDeleteFlow] = React.useState(false);
  const [hidePagination, setHidePagination] = React.useState(false);
  const [delayDatatable, setDelayDatatable] = React.useState(true);

  const sortDirectionOptions = [
    { displayName: "Desc", value: "desc" },
    { displayName: "Asc", value: "asc" },
  ]

  const sortFilterOptions = [
    { id: "0", name: "NO" },
    { id: "1" , name: "YES" },
  ]

  const [searchCriteria, setSearchCriteria] = React.useState({
    keywords: "",
    sort: sortDirectionOptions[0].value,
    resolved: ""
  });

  const getData = () => {
    setLoading(true)
    setHidePagination(false)
    APIClient.getIncidents(pageNum, pageSize, searchCriteria)
      .then((res) => {
        if (res?.eCode === 0) // Success
        {
          let incidents = res?.data
          if (incidents) {
            let newRowCount = rowCount;
            if (incidents.length < pageSize) { newRowCount = (pageNum + 1) * pageSize }
            if (incidents.length > 0) { setRows(incidents); }
            else { setPageNum(pageNum > 0 ? pageNum - 1 : 0); newRowCount = (pageNum) * pageSize }
            if (res?.result?.totalRecords) { newRowCount = res?.result?.totalRecords }
            if (newRowCount !== rowCount) setRowCount(newRowCount)
            setLoading(false)
          }
        }
        else if (res?.res === -1) // Unauthorized
        {
          console.log(res);
        }

      })
      .catch((err) => {
        if (pageNum > 0) {
          setPageNum(pageNum - 1, setRowCount((pageNum) * pageSize))
        }
        setLoading(false)
      })
  }

  const onResult = (success) => {
    setStartResolveFlow(false)
    setStarDeleteFlow(false)
    if (success) {
      getData();
    }
  }

  
  const handleSearchFilterChange = (event) => {
    if(event.target.value)
    {
      setSearchCriteria({ ...searchCriteria, resolved: event.target.value});
    }
    else
    {
      setSearchCriteria({ ...searchCriteria, resolved: "" });
    }
  }


  const handleSortDirectionChange = (event) => {
    setSearchCriteria({ ...searchCriteria, sort: event.target.value });
  }

  const handleKeywordsChange = (event) => {
    setSearchCriteria({ ...searchCriteria, keywords: event.target.value });
  }

  const keyPress = (evt) => {
    if (evt.keyCode === 13) { //Enter
      doSearch();
    }
  }
  const doSearch = () => {
    setRows([])
    if (pageNum > 0) {
      setPageNum(0)
    }
    else {
      getData()
    }
  }

  React.useEffect(() => {
    if (pageSize > -1) {
      getData()
    }
    else {
      setDelayDatatable(false)
    }
  }, [pageSize, pageNum])

  const columns = [
    { field: 'insert_time', headerName: 'Time', minWidth: 150, flex: 1, sortable: false },
    { field: 'source', headerName: 'Source', minWidth: 150, flex: 1, sortable: false },

    {
      field: 'severity', headerName: 'Severity', minWidth: 150, flex: 1, sortable: false, renderCell: (params) => (
        <Rating
          name="rating"
          precision={1}
          max={3}
          value={params.value}
          readOnly
        />
      )
    },
    { field: 'details', headerName: 'Details', minWidth: 150, flex: 1, sortable: false },
    {
      field: 'resolved', headerName: 'Resolved', minWidth: 150, flex: 1, sortable: false, renderCell: (params) => {
        return params.value ? <CheckIcon className='success' /> : <CloseIcon className='fail' />;
      },
    },
    {
      field: 'id', headerName: '', minWidth: 150, flex: 1, sortable: false, align :"center", renderCell: (params) => (
        <div>
          <Link to={generatePath(PATH_LIST.INCIDENT, { 'incidentId': params.value })}>
            <IconButton>
              <VisibilityOutlinedIcon />
            </IconButton>
          </Link>
          {!params.row.resolved &&
            <IconButton onClick={() => { setIncidentId(params.value); setStartResolveFlow(true) }}>
              <CheckCircleOutlineIcon />
            </IconButton>
          }
          <IconButton onClick={() => { setDeleteId(params.value); setStarDeleteFlow(true) }}>
              <DeleteIcon />
            </IconButton>
        </div>
      ),
    }
  ]


  return (
    <Box>
      <Grid container alignItems="center">
      <Grid item xs={12} className="text-center md:text-start mt-10 flex gap-[16px] items-center justify-center flex-wrap md:flex-nowrap">
            <FormControl variant="outlined" className="min-w-[150px]">
              <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
              <OutlinedInput
                label="Search"
                value={searchCriteria.keywords}
                onChange={handleKeywordsChange}
                onKeyDown={keyPress}
              />
            </FormControl>


            <FormControl variant="outlined" className="min-w-[150px]">
              <InputLabel htmlFor="outlined-adornment-search">Resolved</InputLabel>
              <Select
                value={searchCriteria.resolved? searchCriteria.resolved : ''}
                onChange={handleSearchFilterChange}
                disabled={loading}
                label="Resolved"
              >
                <MenuItem value="">All</MenuItem>
                {
                  sortFilterOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="min-w-[150px]">
              <InputLabel htmlFor="outlined-adornment-search">Sort</InputLabel>
              <Select
                value={searchCriteria.sort}
                onChange={handleSortDirectionChange}
                disabled={loading}
                label="Sort"
              >
                {
                  sortDirectionOptions.map((sDirection) => (
                    <MenuItem key={sDirection.value} value={sDirection.value}>{sDirection.displayName}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <Button variant="contained" className='px-[32px]' disabled={loading} onClick={doSearch}>
              <SearchIcon />Search
            </Button>
          </Grid>
      </Grid>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        pageNum={pageNum}
        pageSize={pageSize}
        setPageNum={setPageNum}
        setPageSize={setPageSize}
        hidePagination={hidePagination}
        delayDatatable={delayDatatable}
      />
      <IncidentAction
        incidentId={incidentId}
        startResolveFlow={startResolveFlow}
        onResult={onResult}
      />
      <DeleteModal
        deleteId={deleteId}
        startDeleteFlow={startDeleteFlow}
        apiMethod={APIClient.DeleteIncident}
        needReason={false}
        onResult={onResult}
      />
    </Box>
  );
}

export default Home;
