import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';

export const StyledRoot = styled(Box)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F8FAFF",
    borderRadius: 26,
    padding: '0 20px'
  },
  "& .success": {
    color: theme.palette.text.success
  },
  "& .fail": {
    color: theme.palette.text.fail
  },
  "& .MuiDataGrid-row": {
    border: "1px solid #dedede",
    borderRadius: 20,
    padding: '0 20px',
    maxWidth: '96%'
  },
  "& .MuiDataGrid-cell--withRenderer .MuiIconButton-root": {
    backgroundColor: '#F8F8FA',
    borderRadius: 10,
    margin: 4
  },
  "& .cont-header": {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  "& .data-table .MuiDataGrid-cell:focus-within, & .data-table .MuiDataGrid-columnHeader:focus-within": {
    outline: "none"
  },
  "& .MuiDataGrid-cell--withRenderer .MuiIconButton-root svg": {
    width: '0.8em',
    height: '0.8em'
  },
  "& .MuiFormControl-root:not(.MuiTextField-root)": {
    flexBasis: '32%'
  },
  "& .MuiInputBase-root fieldset": {
    borderRadius: '20px'
  },
  "& .title": {
    color: '#303F83'
  },
  " span[aria-label='3 Stars']": {
    color: theme.palette.text.fail
  },
  " span[aria-label='1 Star']": {
    color: '#06b58d'
  },
  " .btn-accept": {
    backgroundColor: theme.palette.text.success,
    color: "#FFF",
  },
  " .btn-accept:hover": {
    backgroundColor: "#0f6d57"
  },
  " .btn-cancel:not([disabled])": {
    backgroundColor: "#FFF",
    color: theme.palette.text.fail,
    border: "1px solid",
    borderColor: theme.palette.text.fail
  },
  " .btn-purble": {
    backgroundColor: '#7D1470',
    color: "#FFF",
    borderRadius: '50px',
    padding: '20px 28px'
  },
  " .btn-purble:hover": {
    backgroundColor: "#4D1E73"
  },
  " .btn-green": {
    backgroundColor: '#1abe99',
    color: "#FFF",
  },
  " .cont-page": {
    width: '96%',
    margin: '0 auto',
    padding: '32px',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  "& .cont-tabs": {
    width: "96%",
    margin: "0 auto",
  },
  " .cont-tab-headers": {
    backgroundColor: "#FFF",
    borderRadius: "26px",
  },
  "& .cont-btns": {
    marginTop: '32px',
    marginLeft: 0,
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  "& .cont-btns .MuiButton-contained": {
    minWidth: 200,
  },
  "& .cont-controls": {
    "& .MuiIconButton-root": {
      backgroundColor: '#F8F8FA',
      borderRadius: 10,
      margin: 4
    },
    "& .MuiButtonBase-root": {
      borderRadius: 10,
      margin: 4
    }
  },
  "& .MuiSelect-select": {
    display: 'flex',
    alignItems: 'center'
  },
  
  "& .featured-image": {
    maxHeight: 106,
  }
}));
