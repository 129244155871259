import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import { LoadingSkeleton } from './Utils'

function DataTable(props) {
  const handleChangePage = (event, newPageNum) => {
    props.setPageNum(newPageNum);
  };

  const handleChangeRowsPerPage = (event) => {
    let page_size = parseInt(event.target.value, 10);
    props.setPageSize(page_size);
    props.setPageNum(0);
    localStorage.setItem("page_size", page_size);
  };

  React.useEffect(() => {
    if(!props?.delayDatatable)
    {
      let page_size = localStorage.getItem("page_size");
      page_size = parseInt(page_size, 10)
      if (isNaN(page_size)) { page_size = 20 }
      props.setPageSize(page_size);
    }
  }, [props])

  return (
    <DataGrid
      className='mt-8 data-table'
      autoHeight={true}
      getRowSpacing={() => { return { top: 6 } }}
      loading={props.loading}
      rows={props.loading ? [] : props.rows}
      columns={props.columns}
      pagination
      hideFooter={props?.hidePagination}
      components={{
        LoadingOverlay: LoadingSkeleton,
        Pagination: TablePagination
      }}
      componentsProps={{
        pagination: {
          component: "div",
          labelDisplayedRows: ({ from, to }) => `${from}–${to}` + (props?.rowCount !== 999999999 ? ` of ${props?.rowCount}` : ''),
          count: props.rowCount,
          page: props.pageNum,
          rowsPerPage: props.pageSize > 0 ? props.pageSize : 5,
          rowsPerPageOptions: [5, 10, 20],
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }
      }}
      checkboxSelection={false}
      disableSelectionOnClick
      disableRowSelectionOnClick
      disableColumnFilter
      onSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const filter = props.rows.filter((fRow) =>
          selectedIDs.has(fRow.id.toString())
        )
        const selectedData = filter.map(row => Object.fromEntries(
          props.columns.map(
            entry => [
              entry.headerName !== '' ? entry.headerName : entry.field,
              row[entry.field]
            ]
          )
        ));
        props?.setSelectedData && props.setSelectedData(selectedData);
      }}
    />
  );
}

export default DataTable;
