
const apiURL = process.env.REACT_APP_API_BASE_URL;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

export const setCredentials = (key, secret) => {
  sessionStorage.setItem("key", key);
  sessionStorage.setItem("secret", secret);
}

const getHeader = () => {
  let key = sessionStorage.getItem("key");
  let secret = sessionStorage.getItem("secret");
  return { ...headers, Authorization: 'Basic ' + btoa(key + ":" + secret) }
}

export const login = (username, password) =>
  fetch(`${apiURL}/login`, {
    headers: {
      ...headers, Authorization: 'Basic ' + btoa(username + ":" + password)
    }
  })
    .then(res => res.json())
    .then(data => data)


export const getIncidents = (page, limit, searchCriteria) =>
  fetch(`${apiURL}/getIncidents?` + new URLSearchParams({
    page: page,
    limit: limit,
    resolved: searchCriteria.resolved,
    sort: searchCriteria.sort,
    keywords: searchCriteria.keywords
  }), { headers: getHeader() })
    .then(res => res.json())
    .then(data => data)

export const GetIncidentObject = (id) =>
  fetch(`${apiURL}/getIncidents?` + new URLSearchParams({
    id: id
  }), { headers: getHeader() })
    .then(res => res.json())
    .then(data => data)

export const ResolveIncident = (id, rca) =>
  fetch(`${apiURL}/resolve`, {
    method: 'POST',
    headers: getHeader(),
    body: JSON.stringify({
      id: id,
      rca: rca
    })
  })
    .then(res => res.json())
    .then(data => data)

export const DeleteIncident = (id) =>
  fetch(`${apiURL}/delete`, {
    method: 'POST',
    headers: getHeader(),
    body: JSON.stringify({
      id: id
    })
  })
    .then(res => res.json())
    .then(data => data)