import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledModal } from './style';


function ResultModal(props) {

  const onClose = (event, reason) => {
    if(reason !== "escapeKeyDown" && reason !== "backdropClick")
    {
      props.onClose();
    }
  }

  return (

    <Modal
      open={props.modalData.open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModal >
        {
          props.modalData.loading ?
          <CircularProgress color="secondary" />
            :
            props.modalData.success ?
              <CheckCircleIcon className='main-icon success' />
              :
              <ErrorOutlineIcon className='main-icon'/>
        }

        <h3 className={props.modalData.success ? 'success' : 'fail' }>
          {props.modalData.text}
        </h3>
        {
          props.modalData.loading ? null :
            <Button
              variant="contained"
              onClick={props.onClose}
              className="btn-accept"
            >
              Done
            </Button>
        }

      </StyledModal>
    </Modal>
  );
}

export default ResultModal;
