import { React, useState, useEffect, useMemo } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Box from '@mui/material/Box';
import { StyledRoot } from './Components/Shared/StyledRoot';
import Login from './Components/Login';
import Home from './Components/Home';
import ScrollToTop from './Components/Shared/ScrollToTop';
import Alerts from './Components/Shared/Alerts';
import IncidentInfo from './Components/Incident/IncidentInfo'
import TopNav from './Components/Shared/TopNav'
import Drawer from './Components/Shared/Drawer';
import * as APIClient from './Utils/APIClient';
import PATH_LIST from './Utils/pathsConfig';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FunctionsContext } from './Components/Shared/FunctionsContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [alertProps, setAlertProps] = useState({ show: false, type: '', message: '' });
  const [appCredentials, setAppCredentials] = useState({ key: '', secret: '' });
  const [mode, setMode] = useState('light');
  const [drawerOpen, setDrawerOpen] = useState(true);
  const functionsContext = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      toggleDrawer: () => {
        setDrawerOpen((prevOpen) => (!prevOpen));
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#1a459a',
            dark: '#001f54'
          },
          secondary: {
            main: mode === 'light' ? '#1a459a' : '#FEB927'
          },
          text: {
            accent: '#DA035F',
            success: '#1b9e80',
            fail: '#D41010'
          }
        },
      }),
    [mode],
  );

  const showAlert = (alertProps) => {
    this.setState(() => ({
      alertProps: { ...alertProps, show: true }
    }))
    setTimeout(() => {
      this.setState(() => ({
        alertProps: { show: false, type: '', message: '' }
      }))
    }, 5000);
  }

  const setUserCredentials = (key, secret) => {
    setAppCredentials({ key: key, secret: secret })
    setIsLoggedIn(true)
  }

  const userLogout = () => {
    sessionStorage.removeItem('key')
    sessionStorage.removeItem('secret')
    setIsLoggedIn(false)
  }


  useEffect(() => {
    let key = sessionStorage.getItem("key");
    let secret = sessionStorage.getItem("secret");
    if (key && secret) {
      APIClient.login(key, secret)
        .then((res) => {
          if (res?.eCode === 0) {
            setUserCredentials(key, secret);
          }
        })
    }
  }, [])

  return (
    <FunctionsContext.Provider value={functionsContext}>

      <ThemeProvider theme={theme}>
        {isLoggedIn ?
          <StyledRoot sx={{ display: 'flex' }}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Drawer open={drawerOpen} />
              <TopNav userLogout={userLogout} open={drawerOpen} />

              <Box component="main" className="MuiPaper-root"
                sx={{
                  //backgroundColor: (theme) => theme.palette.secondary.main,
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                  marginTop: '64px',
                  padding: '30px'
                }}
              >
                <Routes>
                  <Route path={PATH_LIST.DASHBOARD} element={<Home showAlert={showAlert} userLogout={userLogout} appCredentials={appCredentials} />} />
                  <Route path={PATH_LIST.INCIDENT} element={<IncidentInfo />} />
                  <Route exact path={PATH_LIST.HOME} element={<Home showAlert={showAlert} userLogout={userLogout} appCredentials={appCredentials} />} />
                </Routes>
              </Box>

            </BrowserRouter>
          </StyledRoot>
          :
          <Login showAlert={showAlert} setUserCredentials={setUserCredentials} />
        }
        <ScrollToTop />
        <Alerts alertProps={alertProps} />
      </ThemeProvider>
    </FunctionsContext.Provider>
  )
}

export default App;
