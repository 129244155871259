import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import ResultModal from './ResultModal';
import { StyledModal } from './style';

function DeleteModal(props) {
  const [deleteModalData, setDeleteModalData] = React.useState({ open: false, needReason: props?.needReason, reason: "", reasonEmpty: false });
  const [resultModalData, setResultModalData] = React.useState({ open: false, text: "", success: false });

  const handleDeleteModalOpen = () => setDeleteModalData({ ...deleteModalData, open: true });
  const handleDeleteModalClose = () => {
    setDeleteModalData({ open: false, needReason: props?.needReason, reason: "", reasonEmpty: false }), props?.onResult(false)
  }

  const updateReason = (event) => {
    setDeleteModalData({ ...deleteModalData, reason: event.target.value })
  }


  const onDeleteAction = () => {
    if (deleteModalData.needReason && deleteModalData.reason.trim() === '') {
      setDeleteModalData({ ...deleteModalData, reasonEmpty: true })
    }
    else {
      handleDeleteModalClose();
      setResultModalData({ open: true, loading: true, text: "Processing" })
      if (props.deleteId != -1) {
        props.apiMethod(props.deleteId, deleteModalData.reason).then((res) => {
          if (res?.eCode == 0) {
            setResultModalData({ open: true, loading: false, text: "Record deleted successfully", success: true })
          }
          else {
            setResultModalData({ open: true, loading: false, text: "There was an error deleting record", success: false })
          }
        })
          .catch((error) => {
            setResultModalData({ open: true, loading: false, text: "Request failed", success: false })
          })
      }
      else {
        setResultModalData({ open: true, loading: false, text: "Invalid parameters", success: false })
      }
    }
  }

  const onResultModalClose = () => { setResultModalData({ open: false }), resultModalData.success ? props?.onResult(true) : props?.onResult(false) }

  React.useEffect(() => {
    if (props?.startDeleteFlow && props?.deleteId != -1) {
      handleDeleteModalOpen();
    }
    else {
      handleDeleteModalClose();
    }
  }, [props?.startDeleteFlow])

  return (
    <Box>
      <Modal
        open={deleteModalData.open}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DeleteOutlineRoundedIcon className='main-icon' />
            </Grid>
            <Grid item xs={12}>
              <h2>Are you sure that you want to delete this record?</h2>
            </Grid>
            {deleteModalData.needReason &&
              <Grid item xs={12}>
                <label className="block text-start font-medium">Deletion Reason</label>
                <TextField
                  id="outlined-multiline-static"
                  hiddenLabel
                  fullWidth
                  multiline
                  rows={4}
                  value={deleteModalData.reason}
                  onChange={updateReason}
                  variant="filled"
                />
                {deleteModalData.reasonEmpty ?
                  <h4 className='fail'>
                    Please enter deletion reason
                  </h4>
                  : ''}
              </Grid>
            }
            <Grid item xs={6}>
              <Button
                variant="contained"
                className="btn-accept"
                onClick={onDeleteAction}
              >
                Yes, Delete
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                className="btn-cancel"
                onClick={handleDeleteModalClose}
              >
                No, Keep it
              </Button>
            </Grid>
          </Grid>
        </StyledModal>
      </Modal>
      <ResultModal modalData={resultModalData} onClose={onResultModalClose} />
    </Box>
  );
}

export default DeleteModal;
