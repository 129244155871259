import React, { Component } from 'react'
import { Alert, Snackbar } from '@mui/material/';
//import PropTypes from 'prop-types'

class Alerts extends Component {

  render() {
    let alert;
    const types = ["error", "warning", "info", "success"];
    if(types.includes(this.props.alertProps.type) && this.props.alertProps.show)
    {
      alert = <Alert variant="filled" severity={this.props.alertProps.type}>
      {this.props.alertProps.message}
    </Alert>;
    }
    return (
      <Snackbar open={this.props.alertProps.show} autoHideDuration={6000} sx={{bottom: {lg: 8, xs: 60}}}>
        {alert}
      </Snackbar>
    )
  }
}

export default Alerts