
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="bg-white p-24 mt-20 rounded-16"
      {...other}
    >
      {value === index && (
        <Box className='py-24'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function TabPanelNoBG(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="p-24 mt-20 rounded-16"
      {...other}
    >
      {value === index && (
        <Box className='py-24'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanelNoBG.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const LoadingSkeleton = () => (
  <Box>
    {[...Array(20)].map((val, index) => (
      <Skeleton key={index} variant="rectangular" className="my-32 mx-8" />
    ))}
  </Box>
);
