import * as React from 'react';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Skeleton from '@mui/material/Skeleton';
import { Chip } from '@mui/material';

function IncidentData(props) {

  return (
    <>
      <Grid item xs={12} container rowSpacing={2} className="items-center pt-0" p={0}>
        <Grid item xs={12} className="title">
          <h3>Incident Information</h3>
        </Grid>
        <Grid item xs={12} lg={6} container>
          <Grid item xs={6} lg={4}>
            <p>ID:</p>
          </Grid>
          <Grid item xs={6} lg={8}>
            <p>{props?.loading ? <Skeleton /> : props?.incident?.id}</p>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid item xs={6} lg={4}>
            <p>Time:</p>
          </Grid>
          <Grid item xs={6} lg={8}>
            <p>{props?.loading ? <Skeleton /> : props?.incident?.insert_time}</p>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid item xs={6} lg={4}>
            <p>Source:</p>
          </Grid>
          <Grid item xs={6} lg={8}>
            <p>{props?.loading ? <Skeleton /> : props?.incident?.source}</p>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid item xs={6} lg={4}>
            <p>Severity:</p>
          </Grid>
          <Grid item xs={6} lg={8}>
            <Rating
              name="rating"
              precision={1}
              max={3}
              value={props?.incident?.severity || 0}
              readOnly
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={12}>
            <p>Details:</p>
          </Grid>
          <Grid item xs={12}>
            <p>{props?.loading ? <Skeleton /> : props?.incident?.details}</p>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={12}>
            <p>RCA:</p>
          </Grid>
          <Grid item xs={12}>
            <p>{props?.loading ? <Skeleton /> : props?.incident?.rca}</p>
          </Grid>
        </Grid>

        {props?.incident?.resolved === 1 &&
          <Grid item xs={12} container>
            <Grid item xs={6} lg={4}>
              <p>Status:</p>
            </Grid>
            <Grid item xs={6} lg={8} className='success font-bold'>
              <p> {props?.loading ? <Skeleton /> :  <Chip label="Resolved" color="success" />}</p>
            </Grid>
          </Grid>
        }
        {props?.incident?.deleted === 1 &&
          <Grid item xs={12} container>
            <Grid item xs={6} lg={4}>
              <p>Status:</p>
            </Grid>
            <Grid item xs={6} lg={8} className='fail font-bold'>
              <p> {props?.loading ? <Skeleton /> :  <Chip label="Deleted" color="error" />}</p>
            </Grid>
          </Grid>
        }
      </Grid>
    </>

  );
}

export default IncidentData;
