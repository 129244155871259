import React, { Component } from "react";
import * as Utils from '../../Utils/Utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Container } from "@mui/material";

export default class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  render() {
    const { is_visible } = this.state;
    return (
      <Container sx={{ display: { lg: 'block', xs: 'none'}}}>
        {is_visible && (
           <ArrowUpwardIcon sx={{ fontSize: { lg: 46, xs: 40 }, bottom: {lg : '96px', xs: '72px'}}} onClick={() => Utils.scrollToTop()}
           style={{
            cursor: 'pointer', 
            position: 'fixed', 
            zIndex: 99999, 
            display: 'inline',
            right: '32px',
            color: '#fff',
            backgroundColor: 'rgba(35,35,35,.2)',
            borderRadius: '50%'
          }}/>
        )}
      </Container>
    );
  }
}