import * as React from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IncidentData from './IncidentData';
import IncidentAction from './IncidentAction';
import DeleteModal from '../Shared/ResultModal/DeleteModal';
import * as APIClient from '../../Utils/APIClient'

function IncidentInfo(props) {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [incident, setIncident] = React.useState({});
  const [startResolveFlow, setStartResolveFlow] = React.useState(false);
  const [startDeleteFlow, setStartDeleteFlow] = React.useState(false);

  const getData = () => {
    APIClient.GetIncidentObject(params.incidentId).then((res) => {
      let incident = res.data;
      setIncident(incident); setLoading(false)
    })
      .catch((err) => {
        setLoading(false)
      })
  }

  const onResult = (success) => {
    setStartResolveFlow(false)
    setStartDeleteFlow(false)
    if (success) {
      getData();
    }
  }

  React.useEffect(() => {
    getData();
  }, [params.incidentId])

  return (
    <Box className="cont-page">

      <IncidentData loading={loading} incident={incident} />

      {!loading &&
        <Grid container spacing={2} mt={0} className="justify-center md:justify-start cont-btns">
          {!incident?.resolved && !incident?.deleted &&
            <Button
              variant="contained"
              className='btn-accept'
              disabled={loading}
              onClick={() => { setStartResolveFlow(true) }}
            >
              Resolve
            </Button>
          }
          { !incident?.deleted &&
            <Button
              variant="contained"
              className='btn-cancel'
              disabled={loading}
              onClick={() => { setStartDeleteFlow(true) }}
            >
              Delete
            </Button>
          }
        </Grid>
      }
      <IncidentAction
        incidentId={params.incidentId}
        startResolveFlow={startResolveFlow}
        onResult={onResult}
      />
      <DeleteModal
        deleteId={params.incidentId}
        startDeleteFlow={startDeleteFlow}
        apiMethod={APIClient.DeleteIncident}
        needReason={false}
        onResult={onResult}
      />
    </ Box>
  );
}

export default IncidentInfo;
