import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';

export const StyledModal = styled(Box)(({ theme }) => ({
    "& ": {
        backgroundColor: '#FFF',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 10,
        boxShadow: 24,
        padding: '50px 32px',
        textAlign: 'center',
    },
    "&.wide": {
      width: 800,
      maxWidth: '96%',
    },
    "& .MuiSvgIcon-root.main-icon": {
        width: 100,
        height: 100,
        color: theme.palette.text.accent
    },
    "& h2": {
        margin: '26px auto',
        maxWidth: 280
    },
    "& h3": {
        margin: '10px auto',
        fontWeight: 700,
        maxWidth: 300
    },
    "& .MuiFilledInput-root": {
        marginTop: 10,
        borderRadius: 16,
        backgroundColor: '#FFF',
        border: '1px solid #bebebe'
    },
    "& .MuiButton-root": {
        padding: '10px 16px',
        margin: '10px',
        minWidth: 120
    },
    "& .MuiButton-root.btn-accept": {
        backgroundColor: theme.palette.text.success,
        color: '#FFF',
    },
    "& .success": {
        color: theme.palette.text.success + '!important'
    },
    "& .fail": {
        color: theme.palette.text.fail
    },
    " .btn-cancel:not([disabled])": {
        backgroundColor: "#FFF",
        color: theme.palette.text.fail,
        border: "1px solid #D41010",
        borderColor: theme.palette.text.fail
      },
}));

